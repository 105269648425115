<template>
    <div>
		<van-overlay :show="dialogFormVisible" @click="closeModal">
		  <div class="wrapper" @click.stop>
		    <div class="modal_box">
		    	<div class="dialog_title">
		    		<p>{{$t('Public.JoinUs.modal.title')}}</p>
		    		<div>{{$t('Public.JoinUs.modal.text')}}</div>
		    	</div>
		    	<div class="form_box">
		    		<el-form :model="formData">
		    		  <el-form-item>
		    		    <el-input v-model="formData.username" :placeholder="$t('Public.JoinUs.modal.placeholder.name')"></el-input>
		    		  </el-form-item>
		    		  <el-form-item>
		    		    <el-input v-model="formData.phone" type="tel" maxlength="11" :placeholder="$t('Public.JoinUs.modal.placeholder.phone')"></el-input>
		    		  </el-form-item>
		    		</el-form>
					<el-upload style="text-align: center;"
						  :action="baseUrl+'/api/uploadeImage'"
						  :show-file-list="false"
						  :on-success="handleAvatarSuccess"
						  :before-upload="beforeAvatarUpload">
						<div class="uplod">
							<template v-if="fileName">
								<p>{{fileName}}</p>
							</template>
							<template v-else>
								<div><img src="../../public/images/join/upload_icon.png" ></div>
								<span>{{$t('Public.JoinUs.modal.fileName')}}</span>
							</template>
						</div>
					</el-upload>
		    		<div class="modal_btn" @click="onSubmit">
						{{$t('Public.JoinUs.modal.button')}}
		    		</div>
		    	</div>
		    </div>
			<div class="close_icon" @click="closeModal"><img src="../../public/images/join/close.png" ></div>
		  </div>
		</van-overlay>
		<van-overlay :show="hintVisible" @click="hintVisible = false">
		  <div class="hint_box" @click.stop>
			  <template v-if="submitHint">
				  <div class="hint_icon"><img src="../../public/images/join/success_icon.png" ></div>
				  <div class="hint_text">
						<p>{{$t('Public.JoinUs.modal.successTip')}}</p>
				  </div>
			  </template>
			  <template v-else>
				  <div class="hint_icon"><img src="../../public/images/join/fail_icon.png" ></div>
				  <div class="hint_text  hint_text_error">
						<p>{{$t('Public.JoinUs.modal.failedTip1')}}</p>
						<p>{{$t('Public.JoinUs.modal.failedTip2')}}</p>
				  </div>
			  </template>
			  <div class="modal_btn"  @click="hintVisible = false">{{$t('Public.JoinUs.modal.confirmBtn')}}</div>
		  </div>
		</van-overlay>
    </div>
</template>

<script>
	import { Notify } from 'vant';
export default {
	name: 'ContactModal',
    props: {
		dialogFormVisible: {
			type:Boolean,
			default:false
		},
		jobApplication: {
			type:String,
			default:''
		}
	},
	data(){
		return{
			joinType:1,
			isSubmit:true,
			hintVisible:false,
			fileName:'',
			baseUrl:'',
			formData:{
			  username:'',
			  phone:'',
			  file:'',
			  jobApplication:''
			},
			submitHint:true,
		}
	},
	methods:{
		closeModal(){
			this.$emit('closeModal')
		},
		//文件上传成功时的钩子
		handleAvatarSuccess(res, file) {
			console.log(res,file);
				this.fullscreenLoading.close();
				this.formData.file=res.data;
					this.fileName=file.name;
		},
		//上传文件之前的钩子 
		beforeAvatarUpload(file) {
			// console.log(file.size/1024);
			// if(file.size/1024>5000){
			// 	this.$message.error('抱歉，上传文件不得大于5M！');
			// 	return false
			// }
			this.fullscreenLoading=this.$loading({
			  lock: true,
			  text: '上传中，请稍后',
			  spinner: 'el-icon-loading',
			  background: 'rgba(0, 0, 0, 0.7)'
			});
		},
		onSubmit(){
			var _this=this;
			if(!this.isSubmit){
				return false;
			}
			if(!_this.formData.username){
				Notify(this.$t('Public.JoinUs.modal.nameHint'));
				return false;
			}
			if(!_this.formData.phone){
				Notify(this.$t('Public.JoinUs.modal.phoneHint1'));
				return false;
			}else if(this.verificationPhone()){
				Notify(this.$t('Public.JoinUs.modal.phoneHint2'));
				return false;
			}
			if(!_this.formData.file){
				Notify(this.$t('Public.JoinUs.modal.fileHint'));
				return false;
			}
			this.formData.jobApplication=this.jobApplication;
			this.isSubmit=false;
			this.instance.get('/addRecruitServiceTwo',{
				params:this.formData
			}).then(res=>{
				this.$emit('closeModal');
				this.formData={
					username:'',
					phone:'',
					file:'',
					jobApplication:''
				};
				this.fileName='';
				this.hintVisible=true;
				this.isSubmit=true;
				if(res.data.code==1){
					this.submitHint=true;
				}else{
					this.submitHint=false;
				}
			}); 
		},
		verificationPhone() {
			var _phone=this.formData.phone;
			var reg = /^1[3-9][0-9]\d{4,8}$/i;//验证手机正则(输入前7位至11位)
			if (_phone == "") {
				return true;
			}else if (_phone.length < 11) {
				return true;
			}else if (!reg.test(_phone)) {
				return true;
			}else {
				return false;
			}
		}
	},
	mounted() {
		this.baseUrl=window.location.protocol+'//'+window.location.host;
	}
	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
	.uplod{
		min-width: 2.6rem;
		height: 0.8rem;
		background: #E8F8FF;
		border-radius: 0.1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 0.24rem;
		color: #333333;
		/* margin-top: 0.3rem; */
		cursor: pointer;
		padding:0 0.3rem;
	}
	.uplod div{
		width: 0.44rem;
		margin-right: 0.12rem;
	}
	.wrapper,.hint_box{
		position: absolute;
		top:50%;
		left: 50%;
		z-index: 2000;
		width:5.6rem ;
		transform: translate(-50%,-50%);
	}
	.wrapper{
		height: 8rem;
	}
	.hint_box{
		top:43%;
		padding:0.73rem 0 0.52rem;
		background-color: #fff;
		font-size:0.24rem;
		color:#666;
		text-align:center;
		line-height: 1.5;
	}
	.hint_icon{
		width:1.93rem ;
		margin:0 auto 0.24rem;
	}
	.modal_box{
		width: 5.6rem;
		height: 7.9rem;
		background-color: #fff;
	}
	.dialog_title{
		width:5.6rem;
		height: 2.74rem;
		background: url(../../public/images/join/form_bg.png) no-repeat;
		background-size: 100% 100%;
		padding-top:0.36rem;
		text-align: center;
		font-size: 0.24rem;
		color: #666;
	}
	.dialog_title p{
		font-size: 0.34rem;
		font-weight: 500;
		margin-bottom:0.2rem;
	}
	.form_box{
		width: 100%;
		padding:0.3rem 0.7rem 0;
	}
	.modal_btn{
		width: 2rem;
		height: 0.8rem;
		line-height: 0.8rem;
		background: #05A3E4;
		border-radius: 0.06rem;
		color: #fff;
		text-align: center;
		margin:0.5rem auto 0 ;
	}
	.close_icon{
		width:0.44rem;
		margin:0.5rem auto 0;
	}
	.van-overlay{
		z-index: 1000;
	}
	.hint_text_error{
		color: #e4393c;
	}
</style>
