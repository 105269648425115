var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('van-nav-bar',{attrs:{"title":"职位详情","fixed":"","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"section"},[(!_vm.checkedPost.name)?_c('div',{staticClass:"post_drawer"},[_c('div',{staticClass:"post_title"},[_vm._v("瓴先人才计划")]),_c('p',{staticClass:"post_small_title"},[_vm._v("瓴先人才计划")]),_vm._m(0),_c('p',{staticClass:"post_small_title post_small_title1"},[_vm._v("你将会做什么：")]),_vm._m(1),_c('p',{staticClass:"post_small_title post_small_title1"},[_vm._v("你的工作伙伴")]),_vm._m(2),_c('p',{staticClass:"post_small_title post_small_title1"},[_vm._v("申请要求")]),_c('p',{staticClass:"post_small_title post_small_title1"},[_vm._v("如果你全球TOP 50院校在读或毕业两年内，只要符合以下任意一类即可申请：")]),_vm._m(3),_c('p',{staticClass:"post_small_title post_small_title1"},[_vm._v("关于职位：无具体岗位JD，只要你足够优秀，可量身定制")]),_vm._m(4)]):_c('div',{staticClass:"post_drawer"},[_c('div',{staticClass:"post_title"},[_vm._v(_vm._s(_vm.checkedPost.name))]),_c('p',{staticClass:"post_small_title"},[_vm._v("岗位职责")]),_c('ul',{staticClass:"post_ul"},_vm._l((_vm.checkedPost.duty),function(item,index){return _c('li',{key:index},[_vm._v(_vm._s(item))])}),0),_c('p',{staticClass:"post_small_title"},[_vm._v("任职要求")]),_c('ul',{staticClass:"post_ul"},_vm._l((_vm.checkedPost.request),function(item,index){return _c('li',{key:index,domProps:{"innerHTML":_vm._s(item)}})}),0)])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"submit_btn",on:{"click":_vm.handleApplyPost}},[_vm._v("申请该职位")]),_vm._m(5)]),_c('contact-modal',{attrs:{"dialogFormVisible":_vm.dialogFormVisible,"jobApplication":_vm.jobApplication},on:{"closeModal":_vm.closeModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"post_ul"},[_c('li',[_vm._v("面向全球TOP 50院校招募科技人才，培养及储备能引领未来人工智能与机器学习的业内顶尖专家。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"post_ul"},[_c('li',[_vm._v("进入核心团队，主导或参与核心项目，解决面向保险科技、大出行、新能源等极具挑战性的行业级场景技术问题。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"post_ul"},[_c('li',[_vm._v("来自清华大学、中科院、波士顿大学、纽约大学等国际知名院校；")]),_c('li',[_vm._v("曾就职于世界500强企业及顶级科技公司核心岗位；")]),_c('li',[_vm._v("拥有车联网大数据分析、人工智能、深度机器学习、保险精算等资深专业背景。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"post_ul"},[_c('li',[_vm._v("1、计算机/数学/物理/统计等相关专业，成绩Top 5%；")]),_c('li',[_vm._v("2、在国际顶级会议或期刊上发表过机器学习相关论文；")]),_c('li',[_vm._v("3、参加知名数据挖掘竞赛如KDDCup等并取得优异名次；")]),_c('li',[_vm._v("4、参加知名编程比赛如ACM/ICPC并取得优异名次；")]),_c('li',[_vm._v("5、Top创业公司或世界知名大公司实习经历。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"post_ul"},[_c('li',[_vm._v("诚邀你加入“瓴先人才计划”，与我们共同成长；")]),_c('li',[_vm._v("希望你保持好奇心，从深度/广度不断拓宽自己的能力边界；")]),_c('li',[_vm._v("通过人工智能技术推动时代发展。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"call",attrs:{"href":"tel:010-87856177"}},[_c('div',[_c('img',{attrs:{"src":require("../../public/images/join/dianhua.png")}})]),_c('span',[_vm._v("拨打电话")])])
}]

export { render, staticRenderFns }